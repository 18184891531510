@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');

html {
  height: 100vh;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-block-start: 0;
  margin-block-end: 0;
}

.tag {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgb(239 246 255/var(--tw-bg-opacity));
  border-radius: 100px;
  color: rgb(29 78 216/var(--tw-text-opacity));
  display: block;
  font-size: .875rem;
  line-height: 1.25rem;
  padding: 0.25rem 0.7rem;
  z-index: 10;
}



.fade-out-top {
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=0);
}


@media (max-width: 800px) {
  .internal-users {
    width: 1200px;
  }
}

@media (min-width: 400px) {

  .background-gradient:before {
    background: radial-gradient(20% 50% at 50% 50%, rgba(71, 127, 247, .376) 0, rgba(37, 38, 44, 0) 100%);
    z-index: -10;
    content: "";
    position: absolute;
    inset: 0;
    transform: scale(1);
    pointer-events: none;
    opacity: 0.8
  }
}

.editor {
  max-width: 800px;
  margin: 30px auto;
}

.fade-out-bottom {
  --mask: linear-gradient(to bottom,
      rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0) 100%) 100% 50% / 100% 100% repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);
}




.typingIndicatorBubble {
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 12px;
}

.typingIndicatorBubbleDot {
  width: 8px;
  height: 8px;
  margin-right: 4px;
  border-radius: 50%;
  animation-name: bounce;
  animation-duration: 1.3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.typingIndicatorBubbleDot:first-of-type {
  margin: 0px 4px;
}

.typingIndicatorBubbleDot:nth-of-type(2) {
  animation-delay: 0.15s;
}

.typingIndicatorBubbleDot:nth-of-type(3) {
  animation-delay: 0.3s;
}

@keyframes bounce {

  0%,
  60%,
  100% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(-4px);
  }
}

@keyframes scaleAndFadeIn {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.scale-and-fade-in {
  animation: scaleAndFadeIn 150ms ease-out forwards;
  transform: scale(0.5);
}

@keyframes downAndFadeIn {
  0% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

.down-and-fade-in {
  animation: downAndFadeIn 300ms ease-out forwards;
  transform: translateY(-10px);
}

@keyframes upAndFadeIn {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}

.up-and-fade-in {
  animation: upAndFadeIn 300ms ease-out forwards;
  transform: translateY(10px);
}


@keyframes feedbackPopup {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  15% {
    transform: translateY(0px);
    opacity: 1;
  }


  85% {
    transform: translateY(0px);
    opacity: 1;
  }

  100% {
    transform: translateY(10px);
    opacity: 0;
  }
}

.feedback-popup {
  animation: feedbackPopup 3000ms ease-out forwards;
  transform: translateY(10px);
  opacity: 0;
}